import React from 'react'
import Toppart from '../Component/toppart';
import Signin_Topbar from './Signin_Topbar';
import $ from 'jquery';
import {Link, useNavigate} from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import Footer from './Footer';
import useScript from './Usescript';

export default function Newcaptcha_login() {
    const navigate = useNavigate();
    const recaptchaRef = useRef(null);
    const scriptLoaded = useScript('https://www.google.com/recaptcha/api.js');

  
  const formRef = useRef(null);

    function validateEmail(email) {
        var re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
      }
      setTimeout(loadrecaptcha,900);
     function loadrecaptcha(){
      if (scriptLoaded) {
        if (recaptchaRef.current && !recaptchaRef.current.hasChildNodes()) {
          window.grecaptcha.render(recaptchaRef.current, {
            sitekey: '6Lcl-ugpAAAAAJsE8OcHRwDyjwVcYc7qv3Wkqs2z',
            callback: handleChange
          });
        }
      }
      }
    
      const handleChange = (response) => {
        console.log("reCAPTCHA response:", response);
        // Handle the response as needed
      };
    
       
    

    

    function gowithgoogle(){
        window.location.href='https://app.readydisclosure.com/google-oauth.php';
    }
  return (
    <div>
      <Toppart/>
         <Signin_Topbar />
         <section>
        <div className='reg_small_container'>
            <div className='sign_in_form'>
                <div className='sign_in_cont'>
                    <button className='googlebutton' onClick={gowithgoogle}>Continue With Google Account</button>
                    <p className='ordiv'>OR</p>
                    <form ref={formRef}>
                    <div className='loginform'>
                        <input type="email" name="useremail" id="useremail" placeholder="name@yourdomain.com" />
                    </div>
                    <div className='loginform'>
                        <input type="password" name="pass" id="pass" placeholder="Password"/>
                    </div>
                    <div ref={recaptchaRef}></div>
                    <div className='loginform'>
                    <button type="submit">Login</button>
                    </div>
                    </form>
                    </div>
                    <p className='infotext'>Forgot password? <Link to="/forgotpass">Click Here</Link></p>
                    <div className='bottomredirection'>
                      
                    </div> 
                </div>  
            </div>
        
    </section>
            <Footer />
    </div>
  )
}
