import React from 'react'
import { useEffect,useState } from 'react';

export default function Testdate() {
    const [currentDate, setCurrentDate] = useState('');
    useEffect(() => {
        const today = new Date();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const year = today.getFullYear();
        const usaDate = `${year}-${month}-${day}`; // HTML date input format: YYYY-MM-DD
        setCurrentDate(usaDate);
    }, []);

  return (
    <div>
      <input type="date" id="ad_fun_dates" value={currentDate} onChange={(e) => setCurrentDate(e.target.value)} />
    </div>
  )
}
