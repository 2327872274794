import React from 'react'
import { useNavigate } from 'react-router-dom';
export default function Success_delete_marchent() {
    const navigate = useNavigate();
    setTimeout(redirectotdmain,1000)

    function redirectotdmain(){
        navigate('/clientlist');
    }
  return (
    <div>
      
    </div>
  )
}
