import React from 'react'
import { Link } from 'react-router-dom';
import Logimage from "../images/ReadyDisclosure-Blue.png";
import Userdropdown from '../images/userdropdown.png';
import Notification from '../images/notification.png';
import $ from 'jquery';
import {useNavigate} from 'react-router-dom';
import { useEffect } from 'react';

export default function DashboardTopbar() {
    const navigate = useNavigate();
    var plansts = localStorage.getItem('uplan');
    var usernameval = localStorage.getItem('uname');
    var lud = localStorage.getItem('uid');
    var popus = 0;
    setInterval(calltochknotice,1500);

    useEffect(()=>{
       if(plansts==='Basic Pro'||plansts==='Premium'){
        $('.onlypro').css('display','block');
        $('.gendisclosure').css('display','none')
       }
    })

    function calltochknotice(){
        const obj = { LUID: lud };

        $.ajax({
            type: 'POST',
            url: 'https://app.readydisclosure.com/chk_notification_unread.php',
            data: obj,
            cache: false,
            success: function (result) {
                console.log(result);
                if (result === '0') {
                    $('.reddot').css('display','none')
                } else {
                    $('.reddot').css('display','block')
                }
            },
        });
    }
    function logout(){
        
        navigate('/logout');
    }

    function updateprofile(){
        navigate('/updateprofile');
    }
    function generatediscloser(){
        navigate('/inputquote');
    }
    function goclientlist(){
        navigate('/clientlist');
    }
    function gotonotification(){
        navigate('/notification');
    }
    function openlogoutpop(){
        if(popus==0){
            $('#popuser').css('display','block');
            popus =1;
        }else{
            $('#popuser').css('display','none');
            popus =0;
        }
    }
  return (
    <div>
       <section className="tabssection desktopnav">
        <div className="reg_container">
            <div className="reg_row">
                <div className='one-third nopadding'>
                <div className="logoholder text-center">
                         <img src={Logimage} alt="Bestoption"/>
                </div>
                <p className="logocaption">Compliance Made Easy...</p>
                </div>
                <div className='two-third'>
                    <div className='allmenus'>
                   
                    <span className='menuspan'>
                        <p>{usernameval}</p>
                    </span>
                    <span className='menuspan'>
                        <button onClick={openlogoutpop}><img src={Userdropdown} alt="Dashboard"/></button>
                        <div className='popuser' id="popuser">
                            <div className="fullwidth ptb-10"><button className='linkbtn' onClick={updateprofile}>Profile</button></div>
                            <div className="fullwidth ptb-10 gendisclosure"><button className='linkbtn' onClick={generatediscloser}>Generate Disclosure</button></div>
                            <div className="fullwidth ptb-10 nodisplay onlypro"><button className='linkbtn' onClick={goclientlist}>Client List</button></div>
                            <div className="fullwidth ptb-10"><button className='linkbtn' onClick={logout}>Logout</button></div>                            
                        </div>
                    </span>
                    <span className='menuspan '>
                        <button onClick={gotonotification}><img className='extrasmallimg' src={Notification} alt="Dashboard"/></button>
                        <div className='reddot'>&nbsp;</div>
                    </span>
                </div>
                </div>
            </div>
        </div>
    </section>
    <section className="singlebredcamb">
                <div className="container">
                    <div className="one-full">
                        <p className="breadcampcaption">&nbsp;</p>
                    </div>
                </div>
            </section>
    </div>
  )
}
