import React from 'react'
import Toppart from '../Component/toppart';
import Topbar from './Topbar';
import Footer from './Footer';
export default function requestconf() {
    var eml = localStorage.getItem('uemail')
  return (
    <div>
      <Toppart/>
      <Topbar />
         <section className='mh-adj'>
        <div className='reg_small_container'>
            <div className='noticeheading'>
                <h2>Password Link Sent</h2>
                <p>Please check your inbox {eml}</p>
            </div>
        </div>
        
    </section>
            <Footer />
    </div>
  )
}
