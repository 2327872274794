import React, { useEffect } from 'react';

export default function Logout() {
  
  useEffect(() => {
    // Clear user ID from local storage
    localStorage.setItem('uid', '');
    
    // Use setTimeout to ensure any potential rendering or side effects finish
    setTimeout(() => {
      window.location.replace('https://app.readydisclosure.com/logout.php');
    }, 0); // delay set to 0ms
  }, []);

  return (
    <div>
      Logging out...
    </div>
  );
}
