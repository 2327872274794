import React from 'react'
import DashboardTopbar from './DashboardTopbar'
import Footer from './Footer';
import UplaodIcon from '../images/uploadicon.png'

export default function CommonDashboard() {
  return (
    <div>
      <DashboardTopbar/>
      <section>
        <div className='reg_small_container'>
            <div className='sign_in_form'>
                <div className='sign_in_cont'>
                <h3 class="formheading">User Dashboard</h3>
                <p className='infotext'>This is a demo dashboard that  will be replace by the original one in near future</p>
                <p className='infotext'>You can also email your contact to <br/><a href="#">support@readydisclosure.com </a> for more details.</p>
                    <div className='loginform uplaodform'>
                        <input type="file" name="userimg" id="userimg" />
                        <div className='uploadicon'>
                            <img src={UplaodIcon} alt="Upload user icon"/>
                            <p>User Photo</p>
                        </div>
                    </div>
                   
                    
                </div>  
            </div>
        </div>
    </section>
            <Footer />
    </div>
  )
}
