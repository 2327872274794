import React from 'react'
import DashboardTopbar from './DashboardTopbar';
import $ from 'jquery';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';

export default function Discloser_step_one() {
    const navigate = useNavigate();
    function gofornextstepdiscloser(){
        navigate('/disclosersteptwo');
    }
    return (
        <div>
            <DashboardTopbar />
            <section className="tabssection desktopnav">
                <div className="reg_container">
                    <div className="reg_row">
                        <div className="one-third nopadding" id="tabcrtaccount">
                            <div className="tabscontent firsttab ">
                                <div className="tabnumber activetabnumber">1</div>
                                <i className="fas fa-check-circle iconstr"></i>
                            </div>
                            <p className="tabtext activetebtext">1. Discloser</p>
                        </div>
                        <div className="one-third nopadding dimmer" id="tabidinfo">
                            <div className="tabscontent">
                                <div className="tabnumber">2</div>
                                <i className="fas fa-check-circle iconstr"></i>
                            </div>
                            <p className="tabtext">2. Discloser Step Two</p>
                        </div>
                        <div className="one-third nopadding dimmer" id="tabbilling">
                            <div className="tabscontent lasttab ">
                                <div className="tabnumber">3</div>
                                <i className="fas fa-check-circle iconstr"></i>
                            </div>
                            <p className="tabtext">3. Discloser Step Three</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <h4 className='sectionname'>Discloser</h4>
                <div className='reg_container_small'>
                    <div className='row'>
                        <div className='one-half plr-5'>
                            <div className='formfieldholder nopadding'>
                                <select id="brrowersstate" name="brrowersstate" required>
                                    <option value="">Select State</option>
                                    <option value="California">California</option>
                                    <option value="Connecticut">Connecticut</option>
                                    <option value="Florida">Florida</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Kansas">Kansas</option>
                                    <option value="New York">New York</option>
                                    <option value="Utah">Utah</option>
                                    <option value="Virginia">Virginia</option>
                                </select>
                            </div>
                        </div>
                        <div className='one-half plr-5'>
                            <div className='formfieldholder nopadding'>
                                <select id="mcavalue" name="mcavalue" required>
                                    <option value="">Select MCA Type</option>
                                    <option value="MCA">MCA</option>
                                    <option value="Renewal">Renewal</option>
                                    <option value="Buyout">Buyout</option>
                                   
                                </select>
                            </div>
                        </div>
                       

                    </div>
                    
                    <div className="nextbtndiv pt-60 pb-200">
                        <button id="ntbtn" className="nextbtn" onClick={gofornextstepdiscloser}>NEXT<i className="fas fa-caret-right"></i></button>
                    </div>
                </div>

            </section>
            <Footer />
        </div>
    )
}
