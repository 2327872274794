import React from 'react'
import Toppart from '../Component/toppart';
import Topbar from './Topbar';
import Footer from './Footer';
import $ from 'jquery';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';




export default function Sign_up_one() {
    var useremail = localStorage.getItem('useremail');
setTimeout(printval,1000)
    
   function printval(){
    $('#uemail').val(useremail);
   }

    const navigate = useNavigate();
    function offTheBar(ms){
        var element = document.getElementById(ms);
          element.classList.add("showemptyfield");
    }
    function gofornextstep(){
        
        var usertitle = $('#title').val();
        var fname = $('#fname').val();
        var lname = $('#lname').val();
        var uemail = $('#uemail').val();
        var pass = $('#pass').val();
        var cpass = $('#cpass').val();

        if(usertitle===''){
            offTheBar('usertitle')
        }else if(fname===''){
            offTheBar('fname')
        }else if(lname===''){
            offTheBar('lname')
        }else if(uemail===''){
            offTheBar('uemail')
        }else if(pass===''){
            offTheBar('pass')
        }else if(cpass===''){
            offTheBar('cpass')
        }else{
            if(pass!=cpass){
                alert("Your password is not matching")
            }else{
            var obj = {
                usertitle:usertitle,
                fname:fname,
                lname:lname,
                uemail:uemail,
                pass:pass
            }
            console.log('Step one values : = '+obj)
            $.ajax({
                type: 'POST',
                url: 'https://app.readydisclosure.com/signup_stepone.php',
                data: obj,
                cache: false,
                success: function(result) {           
                   if(result!='fail'){   
                   console.log(result);
                   var uname = fname+' '+lname;
                   localStorage.setItem('uname',uname); 
                   localStorage.setItem('uid',result); 
                   navigate('/signtwo');
                   }else{
                      alert('Plese check your email.'); 
                    }
                },
                });
            }
        }

    }
    const [fname, setMessage] = useState(useremail);
    const handleChange = event => {
        setMessage(event.target.value);
      };
  return (
    <div>
      <Toppart/>
      <Topbar/>
      <section className="tabssection desktopnav">
        <div className="reg_container">
            <div className="reg_row">
                <div className="one-third nopadding" id="tabcrtaccount">
                    <div className="tabscontent firsttab ">
                        <div className="tabnumber activetabnumber">1</div>
                        <i className="fas fa-check-circle iconstr"></i>
                    </div>
                    <p className="tabtext activetebtext">1. Create Your Account</p>
                </div>
                <div className="one-third nopadding dimmer" id="tabidinfo">
                    <div className="tabscontent ">
                        <div className="tabnumber">2</div>
                        <i className="fas fa-check-circle iconstr"></i>
                    </div>
                    <p className="tabtext">2. Business Information</p>
                </div>
                <div className="one-third nopadding dimmer" id="tabbilling">
                    <div className="tabscontent lasttab ">
                        <div className="tabnumber">3</div>
                        <i className="fas fa-check-circle iconstr"></i>
                    </div>
                    <p className="tabtext">3. Billing Information</p>
                </div>
            </div>
        </div>
    </section>
    <section>
        <h4 className='sectionname'>Owner Information</h4>
        <div className='reg_container'>
            <div className='row'>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="text" id="title" name="title" placeholder="Title"/>
                    </div>
                </div>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="text" id="fname" name="fname" placeholder="First Name"/>
                    </div>
                </div>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="text" id="lname" name="lname" placeholder="Last Name"/>
                    </div>
                </div>
                
            </div>
            <div className='row'>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="email" id="uemail" name="uemail" placeholder="Email" value={fname} onChange={handleChange}/>
                    </div>
                </div>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="password" id="pass" name="pass" placeholder="Password"/>
                    </div>
                </div>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="password" id="cpass" name="cpass" placeholder="Confirm Password"/>
                    </div>
                </div>
            </div>
            <div className="nextbtndiv pt-60 pb-200">
                        <button id="ntbtn" className="nextbtn" onClick={gofornextstep}>NEXT<i
                                className="fas fa-caret-right"></i></button>
                        
                    </div>
        </div>
        
    </section>
    <Footer/>
    </div>
  )
}
