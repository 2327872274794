import React from 'react';
import DashboardTopbar from './DashboardTopbar';
import $ from 'jquery';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';

export default function Discloser_step_three() {
    const navigate = useNavigate();
    function previewall(){

    }
    function generate(){

    }
  return (
    <div>
      <DashboardTopbar />
      <section className="tabssection desktopnav">
        <div className="reg_container">
            <div className="reg_row">
                <div className="one-third nopadding" id="tabcrtaccount">
                    <div className="tabscontent firsttab ">
                        <div className="tabnumber">1</div>
                        <i className="fas fa-check-circle iconstr"></i>
                    </div>
                    <p className="tabtext">1.Discloser</p>
                </div>
                <div className="one-third nopadding dimmer" id="tabidinfo">
                    <div className="tabscontent ">
                        <div className="tabnumber ">2</div>
                        <i className="fas fa-check-circle iconstr"></i>
                    </div>
                        <p className="tabtext">2. Discloser Step Two</p>
                </div>
                <div className="one-third nopadding dimmer" id="tabbilling">
                    <div className="tabscontent lasttab ">
                        <div className="tabnumber activetabnumber">3</div>
                        <i className="fas fa-check-circle iconstr"></i>
                    </div>
                    <p className="tabtext activetebtext">3. Discloser Step Three</p>
                </div>
            </div>
        </div>
    </section>
    <section>
        <h4 className='sectionname'>Discloser Step Three</h4>
        <div className='reg_container'>
            <div className='row'>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="text" id="financecharge" name="financecharge" placeholder="Finance Charge" readOnly/>
                    </div>
                </div>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="text" id="totaldollercost" name="totaldollercost" placeholder="Total Dollar Cost" readOnly/>
                    </div>
                </div>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="text" id="netfunding" name="netfunding" placeholder="Net Funding"/>
                    </div>
                </div>
                
                
            </div>
            <div className='row'>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="text" id="estimatetotalpay" name="estimatetotalpay" placeholder="Estimated Total Payment Amount" readOnly/>
                    </div>
                </div>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="text" id="paybackbal" name="paybackbal" placeholder="Payback Balance" readOnly/>
                    </div>
                </div>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="text" id="specifiedper" name="specifiedper" placeholder="Specified Percentage" readOnly/>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="text" id="estimatedcost" name="estimatedcost" placeholder="Estimated Monthly Cost" readOnly/>
                    </div>
                </div>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="text" id="apr" name="apr" placeholder="Estimated Annual Percentage Rate (APR)" readOnly/>
                    </div>
                </div>
                <div className='one-third plr-5'>
                    
                </div>
            </div>
                <div className="nextbtndiv pt-60 pb-200">
                        <button id="preview" className="shortnextbtn" onClick={previewall}>Preview</button>
                        <button id="generate" className="shortnextbtn" onClick={generate}>Generate</button>                        
                </div>
        </div>
        
    </section>
    <Footer/>
    </div>
  )
}
