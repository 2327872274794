import React from 'react'
import Nomenutopbar from './Nomenutopbar';
import Footer from './Footer';

export default function Not_approved() {
  return (
    
    <div>
        <Nomenutopbar/>
      <section>
        <div className='reg_small_container'>
            <div className='sign_in_form'>
                <div className='sign_in_cont text-center'>
                <h3 class="formheading">Your Account Still In Process</h3>
                <p className='infotext'>We have received all of your documents and other information with your request to create an account. Our team is checking your all informations and will approve your request very soon. Plese wait for a while and keep eyes on your email inbox. We will send you an email as soon as we complete the review process. Thank you for your patience. </p>
                <p className='infotext'>You can also email your contact to <br/><a href="mailto:support@readydisclosure.com">support@readydisclosure.com </a></p>
                    </div>
                </div>  
            </div>
    </section>
    <Footer />
    </div>
  )
}
