import React from 'react'
import { Link } from 'react-router-dom';
import Logimage from "../images/ReadyDisclosure-Blue.png";
import Userdropdown from '../images/userdropdown.png';
import Notification from '../images/notification.png';
import $ from 'jquery';
import {useNavigate} from 'react-router-dom';

export default function Nomenutopbar() {
    const navigate = useNavigate();
    var usernameval = localStorage.getItem('uname');
    var popus = 0;
    function openlogoutpop(){
        if(popus===0){
            $('#popuser').css('display','block');
            popus =1;
        }else{
            $('#popuser').css('display','none');
            popus =0;
        }
    }
    function logout(){
        
        navigate('/logout');
    }
  return (
    <div>
      <section className="tabssection desktopnav">
        <div className="reg_container">
            <div className="reg_row">
                <div className='one-third nopadding'>
                <div className="logoholder text-center">
                         <img src={Logimage} alt="Bestoption"/>
                </div>
                <p className="logocaption">Compliance Made Easy...</p>
                </div>
                <div className='two-third'>
                    <div className='allmenus'>
                   
                    <span className='menuspan'>
                        <p>{usernameval}</p>
                    </span>
                    <span className='menuspan'>
                        <button  onClick={openlogoutpop}><img src={Userdropdown} alt="Dashboard"/></button>
                        <div className='popuser' id="popuser">
                           
                            <div className="fullwidth ptb-10"><button className='linkbtn' onClick={logout}>Logout</button></div>                            
                        </div>
                        
                        
                    </span>
                    <span className='menuspan'>
                        <button><img className='extrasmallimg' src={Notification} alt="Dashboard"/></button>
                    </span>
                </div>
                </div>
            </div>
        </div>
    </section>
    <section className="singlebredcamb">
                <div className="container">
                    <div className="one-full">
                        <p className="breadcampcaption">&nbsp;</p>
                    </div>
                </div>
            </section>
    </div>
  )
}
