import React from 'react'
import { Link } from 'react-router-dom';
import Privicon from '../images/lockicon.png';
import Footerlogo from '../images/logo.png';

export default function Footer() {
  return (
    <div className='setatbottom'>
      <section className='footer_sec'>
        <div className='container-fluid'>
            
            <div className='footer_reg_row'>
                <div className='one-full'>                   
                    <div className='footerlinks'>
                        <ul>    
                            <li className='pull-left'><p>Ready DISCLOSURE</p></li>                       
                            <li className='pull-right'><p>© Ready Disclosure 2024</p></li>
                        </ul>
                    </div>
                </div>          
            </div>
        </div>
    </section>
    </div>
  )
}
