import React from 'react'
import Logimage from "../images/ReadyDisclosure-Blue.png";

export default function toppart() {
  return (
    <div>
      <section className="tabssection desktopnav">
        <div className="reg_container">
            <div className="reg_row">
                <div className='reg_one-full nopadding'>
                <div className="logoholder text-center">
                         <img src={Logimage} alt="Bestoption"/>
                </div>
                <p className="logocaption">Compliance Made Easy...</p>
                </div>
            </div>
        </div>
    </section>
    
    </div>
  )
}
