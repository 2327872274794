import React from 'react';
import { Link } from 'react-router-dom';

export default function Signin_Topbar() {
    function offTheBar(ms){
        var element = document.getElementById(ms);
          element.classList.add("deactivetopbar");
    }
  return (
    <div>
       <section>
        <div className='container'>
            <div className='signinbar' id="topbar">
                <p>Don't have an account? <Link  to="/">Sign Up</Link></p>
                <button className='clsbtn' onClick={()=>{offTheBar('topbar')}}>+</button>
            </div>
        </div>
      </section>
    </div>
  )
}
