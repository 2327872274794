import React from 'react';
import DashboardTopbar from './DashboardTopbar';
import $ from 'jquery';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';

export default function Discloser_step_two() {
    const navigate = useNavigate();
    function goforthirdstep(){
        navigate('/discloserstepthree');
    }
  return (
    <div>
      <DashboardTopbar />
      <section className="tabssection desktopnav">
        <div className="reg_container">
            <div className="reg_row">
                <div className="one-third nopadding" id="tabcrtaccount">
                    <div className="tabscontent firsttab ">
                        <div className="tabnumber">1</div>
                        <i className="fas fa-check-circle iconstr"></i>
                    </div>
                    <p className="tabtext">1.Discloser</p>
                </div>
                <div className="one-third nopadding dimmer" id="tabidinfo">
                    <div className="tabscontent ">
                        <div className="tabnumber activetabnumber">2</div>
                        <i className="fas fa-check-circle iconstr"></i>
                    </div>
                    <p className="tabtext activetebtext">2. Discloser Step Two</p>
                </div>
                <div className="one-third nopadding dimmer" id="tabbilling">
                    <div className="tabscontent lasttab ">
                        <div className="tabnumber">3</div>
                        <i className="fas fa-check-circle iconstr"></i>
                    </div>
                    <p className="tabtext">3. Discloser Step Three</p>
                </div>
            </div>
        </div>
    </section>
    <section>
        <h4 className='sectionname'>Discloser Step Two</h4>
        <div className='reg_container'>
            <div className='row'>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="text" id="grossfuncing" name="grossfuncing" placeholder="Gross Funding"/>
                    </div>
                </div>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="text" id="estimatemonthly" name="estimatemonthly" placeholder="Estimated Monthly Revenue"/>
                    </div>
                </div>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="text" id="dailypayment" name="dailypayment" placeholder="Daily Payment"/>
                    </div>
                </div>
                
                
            </div>
            <div className='row'>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="text" id="bankstatement" name="bankstatement" placeholder="Bank Statements Used"/>
                    </div>
                </div>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="text" id="orgfee" name="orgfee" placeholder="Origination Fee"/>
                    </div>
                </div>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="text" id="brokerfee" name="brokerfee" placeholder="Broker Fee"/>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='one-third plr-5'>
                    <div className='formfieldholder nopadding'>
                        <input type="text" id="estimatedterms" name="estimatedterms" placeholder="Estimated Term"/>
                    </div>
                </div>
                <div className='one-third plr-5'>
                   
                </div>
                <div className='one-third plr-5'>
                    
                </div>
            </div>
            <div className="nextbtndiv pt-60 pb-200">
                        <button id="ntbtn" className="nextbtn" onClick={goforthirdstep}>NEXT<i
                                className="fas fa-caret-right"></i></button>
                        
                    </div>
        </div>
        
    </section>
    <Footer/>
    </div>
  )
}
