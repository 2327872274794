import React from 'react'
import $ from 'jquery';
import DashboardTopbar from './DashboardTopbar';
import { Link, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import { useEffect, useState } from 'react';

export default function Premium_landing() {
    const [currentDate, setCurrentDate] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const today = new Date();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const year = today.getFullYear();
        const usaDate = `${year}-${month}-${day}`; // HTML date input format: YYYY-MM-DD
        setCurrentDate(usaDate);
    }, []);

    function saveuserdata() {
        var luid = localStorage.getItem('uid');
        var ad_fun_date = $('#ad_fun_date').val();
        var bf_bname = $('#bf_bname').val();
        var bf_dba_aka = $('#bf_dba_aka').val();
        var bf_entity_type = $('#bf_entity_type').val();
        var ba_city = $('#ba_city').val();
        var ba_state = $('#ba_state').val();
        var ba_zip = $('#ba_zip').val();
        var ba_baddress = $('#ba_baddress').val();
        var ma_city = $('#ma_city').val();
        var ma_state = $('#ma_state').val();
        var ma_zip = $('#ma_zip').val();
        var ma_email = $('#ma_email').val();
        var ma_maddress = $('#ma_maddress').val();

        var ma_phone = $('#ma_phone').val();

        var op_owner_title = $('#op_owner_title').val();
        var op_owner_fname = $('#op_owner_fname').val();
        var op_owner_lname = $('#op_owner_lname').val();

        var op_owner_ssn = $('#op_owner_ssn').val();
        var op_owner_driving = $('#op_owner_driving').val();
        var bi_origination_fee = $('#bi_origination_fee').val();
        var bi_bank_name = $('#bi_bank_name').val();
        var bi_duration = $("input[name='bi_duration']:checked").val();

        var bi_nameof_account = $('#bi_nameof_account').val();
        var bi_account_number = $('#bi_account_number').val();
        var bi_routing_number = $('#bi_routing_number').val();

        var ucc_filling_fee = $('#ucc_filling_fee').val();
        var default_fee = $('#default_fee').val();
        var nsf_fee = $('#nsf_fee').val();
        var month_servicing_fee = $('#month_servicing_fee').val();
        var disbursement_fee = $('#disbursement_fee').val();
        var disbursement_amount = $('#disbursement_amount').val();
        var monthly_average_sales = $('#monthly_average_sales').val();
        var annual_sales = $('#annual_sales').val();
        var date_business_started = $('#date_business_started').val();
        var funding_fee = $('#funding_fee').val();
        var payoff_existing_balance = $('#payoff_existing_balance').val();

        if (ad_fun_date === '') {
            offTheBar('ad_fun_date')
        } else if (bf_bname === '') {
            offTheBar('bf_bname')
        } else if (bf_dba_aka === '') {
            offTheBar('bf_dba_aka')
        } else if (bf_entity_type === '') {
            offTheBar('bf_entity_type')
        } else if (ba_city === '') {
            offTheBar('ba_city')
        } else if (ba_state === '') {
            offTheBar('ba_state')
        } else if (ba_zip === '') {
            offTheBar('ba_zip')
        } else if (ma_city === '') {
            offTheBar('ma_city')
        } else if (ma_state === '') {
            offTheBar('ma_state')
        } else if (ma_zip === '') {
            offTheBar('ma_zip')
        } else if (ma_email === '') {
            offTheBar('ma_email')
        } else if (ma_phone === '') {
            offTheBar('ma_phone')
        } else if (op_owner_title === '') {
            offTheBar('op_owner_title')
        } else if (op_owner_fname === '') {
            offTheBar('op_owner_fname')
        } else if (op_owner_lname === '') {
            offTheBar('op_owner_lname')
        } else if (op_owner_ssn === '') {
            offTheBar('op_owner_ssn')
        } else if (op_owner_driving === '') {
            offTheBar('op_owner_driving')
        } else if (bi_origination_fee === '') {
            offTheBar('bi_origination_fee')
        } else if (bi_bank_name === '') {
            offTheBar('bi_bank_name')
        } else if (bi_duration === '') {
            offTheBar('bi_duration')
        } else if (ma_maddress === '') {
            offTheBar('ma_maddress')
        } else if (ba_baddress === '') {
            offTheBar('ba_baddress')
        } else if (ucc_filling_fee === '') {
            offTheBar('ucc_filling_fee')
        } else if (default_fee === '') {
            offTheBar('default_fee')
        } else if (nsf_fee === '') {
            offTheBar('nsf_fee')
        } else if (month_servicing_fee === '') {
            offTheBar('month_servicing_fee')
        } else if (disbursement_fee === '') {
            offTheBar('disbursement_fee')
        } else if (disbursement_amount === '') {
            offTheBar('disbursement_amount')
        } else if (monthly_average_sales === '') {
            offTheBar('monthly_average_sales')
        } else if (annual_sales === '') {
            offTheBar('annual_sales')
        } else if (date_business_started === '') {
            offTheBar('date_business_started')
        } else if (funding_fee === '') {
            offTheBar('funding_fee')
        } else if (payoff_existing_balance === '') {
            offTheBar('payoff_existing_balance')
        } else {
            localStorage.setItem('paymentduration', bi_duration);
            localStorage.setItem('origination_fee', bi_origination_fee);
            var obj = {
                ad_fun_date: ad_fun_date,
                bf_bname: bf_bname,
                bf_dba_aka: bf_dba_aka,
                bf_entity_type: bf_entity_type,
                ba_city: ba_city,
                ba_state: ba_state,
                ba_baddress: ba_baddress,
                ba_zip: ba_zip,
                ma_city: ma_city,
                ma_state: ma_state,
                ma_zip: ma_zip,
                ma_email: ma_email,
                ma_phone: ma_phone,
                ma_maddress: ma_maddress,
                op_owner_title: op_owner_title,
                op_owner_fname: op_owner_fname,
                op_owner_lname: op_owner_lname,
                op_owner_ssn: op_owner_ssn,
                op_owner_driving: op_owner_driving,
                bi_origination_fee: bi_origination_fee,
                bi_bank_name: bi_bank_name,
                bi_duration: bi_duration,
                bi_nameof_account: bi_nameof_account,
                bi_account_number: bi_account_number,
                bi_routing_number: bi_routing_number,
                ucc_filling_fee: ucc_filling_fee,
                default_fee: default_fee,
                nsf_fee: nsf_fee,
                month_servicing_fee: month_servicing_fee,
                disbursement_fee: disbursement_fee,
                disbursement_amount: disbursement_amount,
                monthly_average_sales: monthly_average_sales,
                annual_sales: annual_sales,
                date_business_started: date_business_started,
                funding_fee: funding_fee,
                payoff_existing_balance: payoff_existing_balance,
                LUID: luid
            }
            console.log(obj);
            $.ajax({
                type: 'POST',
                url: 'https://app.readydisclosure.com/savepremiumprofile.php',
                data: obj,
                cache: false,
                success: function (result) {
                    console.log(result);
                    if (result !== 'fail') {
                       var arr = result.split('|')
                        localStorage.setItem('marchid', arr[0]);
                        localStorage.setItem('mrchid', arr[1]);
                        navigate('/inputquote');
                    } else {
                        alert('We cannot save your record.');
                    }
                },
            });
        }
    }
    function format_conv_money(id) {
        var amt = $('#' + id).val();
        var amtdoler = formatMoney(amt);
        $('#' + id).val(amtdoler);
    }

    function formatMoney(value) {
        // Convert the string to a number
        const number = parseFloat(value);

        // Check if the input is a valid number
        if (isNaN(number)) {
            return '0.00';
        }

        // Convert the number to a string with commas for thousands separators
        const formattedValue = number.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        });

        return formattedValue;
    }

    function formatSSN(ssn) {
        // Remove any non-digit characters
        ssn = ssn.replace(/\D/g, '');

        // Check if the SSN has exactly 9 digits
        if (ssn.length !== 9) {
            alert('SSN must be exactly 9 digits. Please check your SSN');
            return '';
        }
        // Format the SSN as XXX-XX-XXXX
        return ssn.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
    }

    function formatssn(id) {
        var ssntext = $('#' + id).val();
        var finalssn = formatSSN(ssntext);
        $('#' + id).val(finalssn);
    }

    function offTheBar(ms) {
        var element = document.getElementById(ms);
        element.classList.add("showemptyfield");
    }


    function offonsection(offsec, onsec, onbtn, offbtn) {
        $('.' + onsec).css('display', 'inline-block');
        $('.' + offsec).css('display', 'none');
        $('.' + offbtn).removeClass('active');
        $('.' + onbtn).addClass('active');
    }

    return (
        <div>
            <div className="default-h">
                <DashboardTopbar />


                <div className="container">
                    <div className="p-50-0 align-item-center">
                        <h3>Premium</h3>
                    </div>

                    <div className="prem-tab row-3">
                        <button onClick={() => offonsection('extrasection', 'profilesection', 'profilebtn', 'extrabtn')} className="button pull-right extrapadding active profilebtn">PROFILE</button>
                        <button className="button pull-right extrapadding extrabtn" onClick={() => offonsection('profilesection', 'extrasection', 'extrabtn', 'profilebtn')}>EXTRA</button>
                        <Link to="/clientlist" className="button pull-right">BACK</Link>
                    </div>


                    <div className='merchant_sectionpart profilesection'>
                        <div className="prem-section">
                            <div className="p-section-title">ADVENTURE FUNDING GROUP</div>
                            <div className="item"><input type="date" id="ad_fun_date" value={currentDate} onChange={(e) => setCurrentDate(e.target.value)} /></div>
                        </div>

                        <div className="prem-section">
                            <div className="p-section-title">Business Profile</div>
                            <div className="field-group-set row-3">
                                <div className="item">
                                    <div className="f-label">Business Name</div>
                                    <input type="text" placeholder="Business Name..." id="bf_bname" />
                                </div>

                            </div>

                            <div className="field-group-set row-3">

                                <div className="item">
                                    <div className="f-label">DBA AKA</div>
                                    <input type="text" placeholder="Doing Business As..." id="bf_dba_aka" />
                                </div>

                                <div className="item">
                                    <div className="f-label">Enity Type</div>
                                    <select name="" id="bf_entity_type">
                                        <option value="CORPORATION">CORPORATION</option>
                                        <option value="LIMITED LIABILITY COMPANY">LIMITED LIABILITY COMPANY</option>
                                        <option value="LIMITED PARTNERSHIP">LIMITED PARTNERSHIP</option>
                                        <option value="LIMITED LIABILITY PARTNERSHIP">LIMITED LIABILITY PARTNERSHIP</option>
                                        <option value="SOLE PROPRIETOR">SOLE PROPRIETOR</option>
                                    </select>
                                </div>



                            </div>


                            <div className="p-section-subtitle">Business Address</div>

                            <div className="field-group-set row-1">
                                <div className="fullwidthitem">
                                    <div className="f-label">Business Address</div>
                                    <input type="text" name="" id="ba_baddress" placeholder='Business Address' />
                                </div>
                            </div>

                            <div className="field-group-set row-3">
                                <div className="item">
                                    <div className="f-label">City</div>
                                    <input type="text" name="" id="ba_city" placeholder='City' />
                                </div>

                                <div className="item">
                                    <div className="f-label">State</div>
                                    <select name="" id="ba_state">
                                        <option value="">Select State</option>
                                        <option value="California">California</option>
                                        <option value="Connecticut">Connecticut</option>
                                        <option value="Florida">Florida</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Kansas">Kansas</option>
                                        <option value="New York">New York</option>
                                        <option value="Utah">Utah</option>
                                        <option value="Virginia">Virginia</option>
                                    </select>
                                </div>

                                <div className="item">
                                    <div className="f-label">Zip</div>
                                    <input type="text" placeholder="ZIP Code..." id="ba_zip" />
                                </div>

                            </div>

                            <div className="p-section-subtitle">Merchant Address</div>

                            <div className="field-group-set row-1">
                                <div className="fullwidthitem">
                                    <div className="f-label">Merchant Address</div>
                                    <input type="text" name="" id="ma_maddress" placeholder='Merchant Address' />
                                </div>
                            </div>

                            <div className="field-group-set row-3">
                                <div className="item">
                                    <div className="f-label">City</div>
                                    <input type="text" name="" id="ma_city" placeholder='City' />

                                </div>

                                <div className="item">
                                    <div className="f-label">State</div>
                                    <select name="" id="ma_state">
                                        <option value="">Select State</option>
                                        <option value="California">California</option>
                                        <option value="Connecticut">Connecticut</option>
                                        <option value="Florida">Florida</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Kansas">Kansas</option>
                                        <option value="New York">New York</option>
                                        <option value="Utah">Utah</option>
                                        <option value="Virginia">Virginia</option>
                                    </select>
                                </div>

                                <div className="item">
                                    <div className="f-label">Zip</div>
                                    <input type="text" placeholder="ZIP Code..." id="ma_zip" />
                                </div>

                            </div>

                            <div className="field-group-set row-3">

                                <div className="item">
                                    <div className="f-label">Email</div>
                                    <input type="email" placeholder="Email Address..." id="ma_email" />
                                </div>

                                <div className="item">
                                    <div className="f-label">Phone Number</div>
                                    <input type="tel" placeholder="Phone Number..." id="ma_phone" />
                                </div>
                            </div>
                        </div>

                        <div className="prem-section">
                            <div className="p-section-title">Owner Profile</div>
                            <div className="field-group-set row-3">
                                <div className="item">
                                    <div className="f-label">Owner Title</div>
                                    <input type="text" name="" id="op_owner_title" placeholder='Owner Title' />

                                </div>

                                <div className="item">
                                    <div className="f-label">Owner First Name</div>
                                    <input type="text" placeholder="Owner First Name..." id="op_owner_fname" />
                                </div>

                                <div className="item">
                                    <div className="f-label">Owner Last Name</div>
                                    <input type="text" placeholder="Owner Last Name..." id="op_owner_lname" />
                                </div>


                            </div>

                            <div className="field-group-set row-3">

                                <div className="item">
                                    <div className="f-label">Social Security Number</div>
                                    <input type="text" placeholder="Social Security Number..." id="op_owner_ssn" onBlur={() => { formatssn('op_owner_ssn') }} />
                                </div>

                                <div className="item">
                                    <div className="f-label">Drivers License Number</div>
                                    <input type="text" placeholder="Drivers License Number..." id="op_owner_driving" />
                                </div>

                            </div>

                        </div>

                        <div className="prem-section">
                            <div className="p-section-title">Bank Info</div>

                            <div className="field-group-set row-3">

                                <div className="item">
                                    <div className="f-label">Underwriting Fee/Orgination Fee</div>
                                    <input type="text" placeholder="$0.00" id="bi_origination_fee" onBlur={() => { format_conv_money('bi_origination_fee') }} />
                                </div>

                                <div className="item">
                                    <div className="f-label">Bank Name</div>
                                    <input type="text" placeholder="Bank Name ..." id="bi_bank_name" />
                                </div>

                                <div className="item">
                                    <div className="f-label">Duration</div>
                                    <div className="row">
                                        <span className="newradio"><input type="radio" name="bi_duration" value="Daily" /> Daily</span>
                                        <span className="newradio"><input type="radio" name="bi_duration" value="Weekly" /> Weekly</span>
                                        <span className="newradio"><input type="radio" name="bi_duration" value="Bi-Weekly" /> Biweekly</span>
                                    </div>
                                </div>

                            </div>

                            <div className="field-group-set row-3">

                                <div className="item">
                                    <div className="f-label">Name Of Account(Optional)</div>
                                    <input type="text" placeholder="Name Of Account..." id="bi_nameof_account" />
                                </div>

                                <div className="item">
                                    <div className="f-label">Account Number(Optional)</div>
                                    <input type="text" placeholder="Account Number(Optional)" id="bi_account_number" />
                                </div>

                                <div className="item">
                                    <div className="f-label">Routing Number(Optional)</div>
                                    <input type="text" placeholder="Routing Number" id="bi_routing_number" />
                                </div>

                            </div>

                        </div>


                        <div className="prem-send">
                            <button type="button" onClick={() => offonsection('profilesection', 'extrasection', 'extrabtn', 'profilebtn')}>Next</button>
                        </div>

                    </div>

                    <div className='merchant_sectionpart extrasection'>
                        <div class="prem-section">


                            <div class="field-group-set row-3">

                                <div class="item">
                                    <div class="f-label">UCC Filing Fee</div>
                                    <input type="text" placeholder="$0.00" onBlur={() => { format_conv_money('ucc_filling_fee') }} id="ucc_filling_fee" />
                                </div>

                                <div class="item">
                                    <div class="f-label">Default Fee</div>
                                    <input type="text" placeholder="$0.00" onBlur={() => { format_conv_money('default_fee') }} id="default_fee" />
                                </div>

                                <div class="item">
                                    <div class="f-label">Nsf Fee</div>
                                    <input type="text" placeholder="$0.00" onBlur={() => { format_conv_money('nsf_fee') }} id="nsf_fee" />
                                </div>

                            </div>

                            <div class="field-group-set row-3">

                                <div class="item">
                                    <div class="f-label">Month Servicing Fee</div>
                                    <input type="text" placeholder="$0.00" onBlur={() => { format_conv_money('month_servicing_fee') }} id="month_servicing_fee" />
                                </div>

                                <div class="item">
                                    <div class="f-label">Disbursement Fee</div>
                                    <input type="text" placeholder="$0.00" onBlur={() => { format_conv_money('disbursement_fee') }} id="disbursement_fee" />
                                </div>

                                <div class="item">
                                    <div class="f-label">Disbursement Amount</div>
                                    <input type="text" placeholder="$0.00" onBlur={() => { format_conv_money('disbursement_amount') }} id="disbursement_amount" />
                                </div>

                            </div>
                            <div class="field-group-set row-3">

                                <div class="item">
                                    <div class="f-label">Monthly Average Sales</div>
                                    <input type="text" placeholder="$0.00" onBlur={() => { format_conv_money('monthly_average_sales') }} id="monthly_average_sales" />
                                </div>

                                <div class="item">
                                    <div class="f-label">Annual Sales</div>
                                    <input type="text" placeholder="$0.00" onBlur={() => { format_conv_money('annual_sales') }} id="annual_sales" />
                                </div>

                                <div class="item">
                                    <div class="f-label">Date Business Started</div>
                                    <input type="date" id="date_business_started" />
                                </div>

                            </div>
                            <div class="field-group-set row-3">

                                <div class="item">
                                    <div class="f-label">Funding Fee</div>
                                    <input type="text" placeholder="$0.00" onBlur={() => { format_conv_money('funding_fee') }} id="funding_fee" />
                                </div>

                                <div class="item">
                                    <div class="f-label">Payoff Existing Balance</div>
                                    <input type="text" placeholder="$0.00" onBlur={() => { format_conv_money('payoff_existing_balance') }} id="payoff_existing_balance" />
                                </div>


                            </div>

                        </div>
                        <div class="prem-send">
                            <button type="button" onClick={saveuserdata}>Next</button>
                        </div>
                    </div>
                </div>


                <Footer />
            </div>
        </div>
    )
}
