import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Inputquote from './Component/Inputquote';
import PreSign_up from './Component/PreSign_up';
import Signupone from "./Component/Sign_up_one";
import Signuptwo from "./Component/Sign_up_two";
import Signupthree from './Component/Sign_up_three'
import Login from './Component/Login';
import Forgot_pass from './Component/Forgot_pass';
import Dashboardbasic from './Component/Dashboardbasic';
import Requestconf from './Component/requestconf'
import CommonDashboard from './Component/CommonDashboard';
import Discloser_step_one from './Component/Discloser_step_one';
import Discloser_step_two from './Component/Discloser_step_two';
import Discloser_step_three from './Component/Discloser_step_three';
import Terms_or_services from './Component/Terms_or_services';
import Privacy_pollicy from './Component/Privacy_pollicy';
import Logout from './Component/Logout';
import Update_billing_info from './Component/Update_billing_info';
import Update_profile from './Component/Update_profile';
import Invoice_listing from './Component/Invoice_listing';
import Newcaptcha_login from './Component/Newcaptcha_login';
import Not_approved from './Component/Not_approved';
import Notification from './Component/Notification';
import Client_list from './Component/Client_list';
import Editmerchant from './Component/Editmerchant';
import Merchant_preview from './Component/Merchant_preview';
import Direct_Editmerchant from './Component/Direct_Editmerchant';
import Success_delete_marchent from './Component/Success_delete_marchent';
import Disclosurelist from './Component/Disclosurelist';
import Premium_landing from './Component/Premium_landing';
import Bitty_ext from './Component/Bitty_ext';
import Testdate from './Component/Testdate';
import Direct_premium_editmerchant from './Component/Direct_premium_editmerchant';
import Search_clientlist from './Component/Search_clientlist';


function App() {
  return (
    <section className='singleheight'>
    <Router>     
    <div className='fullwidth'>         
      <Routes>
        <Route exact path="/" element={<PreSign_up/>} />
        <Route exact path="/signone" element={<Signupone/>}/>
        <Route exact path="/signtwo" element={<Signuptwo/>}/>
        <Route exact path="/signthree" element={<Signupthree/>}/>
        <Route exact path="/login" element={<Login/>}/>
        <Route exact path="/forgotpass" element={<Forgot_pass/>}/>
        <Route exact path="/dashboardbasic" element={<Dashboardbasic/>}/>
        <Route exact path="/requestconf" element={<Requestconf/>}/>
        <Route exact path="/commondashboard" element={<CommonDashboard/>}/>
        <Route exact path="/inputquote" element={<Inputquote/>}/>
        <Route exact path="/discloserstepone" element={<Discloser_step_one/>}/>
        <Route exact path="/disclosersteptwo" element={<Discloser_step_two/>}/>
        <Route exact path="/discloserstepthree" element={<Discloser_step_three/>}/>
        <Route exact path="/termsofservice" element={<Terms_or_services/>}/>
        <Route exact path="/privacypolicy" element={<Privacy_pollicy/>}/>
        <Route exact path="/logout" element={<Logout/>}/>
        <Route exact path="/updatebillinginfo" element={<Update_billing_info/>}/>
        <Route exact path="/updateprofile" element={<Update_profile/>}/>
        <Route exact path="/invoicelisting" element={<Invoice_listing/>}/>
        <Route exact path="/newcaptcha" element={<Newcaptcha_login/>}/>
        <Route exact path="/noapproved" element={<Not_approved/>}/>
        <Route exact path="/notification" element={<Notification/>}/>
        <Route exact path="/clientlist" element={<Client_list/>}/>
        <Route exact path="/editmerchant" element={<Editmerchant/>}/>
        <Route exact path="/merchant_preview" element={<Merchant_preview/>}/>
        <Route exact path="/direct_editmerchant" element={<Direct_Editmerchant/>}/>
        <Route exact path="/succdessdelete" element={<Success_delete_marchent/>}/>
        <Route exact path="/disclosurelist" element={<Disclosurelist/>}/>
        <Route exact path="/premium_form" element={<Premium_landing/>}/>
        <Route exact path="/bitty_ext" element={<Bitty_ext/>}/>
        <Route exact path="/testdate" element={<Testdate/>}/>
        <Route exact path="/direct_premium_editmerchant" element={<Direct_premium_editmerchant/>}/>
        <Route exact path="/searchclient_list" element={<Search_clientlist/>}/>
        
      </Routes>  
    </div>
    </Router>
  </section>
  );
}

export default App;
