import React from 'react'
import $ from 'jquery';
import DashboardTopbar from './DashboardTopbar';
import { Link, useNavigate } from 'react-router-dom';
import Footer from './Footer';

export default function Bitty_ext() {
    const navigate = useNavigate();

    function saveuserdata() {
        var luid = localStorage.getItem('uid');
        var merchid = localStorage.getItem('marchid');
        var ucc_filling_fee = $('#ucc_filling_fee').val();
        var default_fee = $('#default_fee').val();
        var nsf_fee = $('#nsf_fee').val();
        var month_servicing_fee = $('#month_servicing_fee').val();
        var disbursement_fee = $('#disbursement_fee').val();
        var disbursement_amount = $('#disbursement_amount').val();
        var monthly_average_sales = $('#monthly_average_sales').val();
        var annual_sales = $('#annual_sales').val();
        var date_business_started = $('#date_business_started').val();
        var funding_fee = $('#funding_fee').val();
        var payoff_existing_balance = $('#payoff_existing_balance').val();

        if (ucc_filling_fee === '') {
            offTheBar('ucc_filling_fee')
        }else if(default_fee === ''){
            offTheBar('default_fee')
        }else if(nsf_fee === ''){
            offTheBar('nsf_fee')
        }else if(month_servicing_fee === ''){
            offTheBar('month_servicing_fee')
        }else if(disbursement_fee === ''){
            offTheBar('disbursement_fee')
        }else if(disbursement_amount === ''){
            offTheBar('disbursement_amount')
        }else if(monthly_average_sales === ''){
            offTheBar('monthly_average_sales')
        }else if(annual_sales === ''){
            offTheBar('annual_sales')
        }else if(date_business_started === ''){
            offTheBar('date_business_started')
        }else if(funding_fee === ''){
            offTheBar('funding_fee')
        }else if(payoff_existing_balance === ''){
            offTheBar('payoff_existing_balance')
        }else{
        var obj = {
            ucc_filling_fee: ucc_filling_fee,
            default_fee: default_fee,
            nsf_fee: nsf_fee,
            month_servicing_fee: month_servicing_fee,
            disbursement_fee: disbursement_fee,
            disbursement_amount: disbursement_amount,
            monthly_average_sales: monthly_average_sales,
            annual_sales: annual_sales,
            date_business_started: date_business_started,
            funding_fee: funding_fee,
            payoff_existing_balance: payoff_existing_balance,
            merchid:merchid,
            LUID:luid
        }
    
        console.log(obj);
        $.ajax({
            type: 'POST',
            url: 'https://app.readydisclosure.com/updatepremiumprofile.php',
            data: obj,
            cache: false,
            success: function (result) {
                console.log(result);
                if (result !== 'fail') {
                    localStorage.setItem('marchid', result);
                    navigate('/inputquote');
                } else {
                    alert('We cannot save your record.');
                }
            },
        });
    }

    }

    function format_conv_money(id) {
        var amt = $('#' + id).val();
        var amtdoler = formatMoney(amt);
        $('#' + id).val(amtdoler);
    }

    function formatMoney(value) {
        // Convert the string to a number
        const number = parseFloat(value);

        // Check if the input is a valid number
        if (isNaN(number)) {
            return '0.00';
        }

        // Convert the number to a string with commas for thousands separators
        const formattedValue = number.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        });

        return formattedValue;
    }

    function offTheBar(ms) {
        var element = document.getElementById(ms);
        element.classList.add("showemptyfield");
    }

    return (
        <div>
            <div class="default-h">
                <DashboardTopbar />


                <div class="container">
                    <div class="p-50-0 align-item-center">
                        <h3>Premium</h3>
                    </div>

                    <div class="prem-tab row-3">
                        <Link to="/premium_form" class="button">PROFILE</Link>
                        <Link to="/bitty_ext" class="button active">EXTRA</Link>
                        <Link to="/clientlist" className="button pull-right">BACK</Link>
                    </div>



                    <div class="prem-section">
                        

                        <div class="field-group-set row-3">

                            <div class="item">
                                <div class="f-label">UCC Filing Fee</div>
                                <input type="text" placeholder="$0.00" onBlur={()=>{format_conv_money('ucc_filling_fee')}} id="ucc_filling_fee"/>
                            </div>

                            <div class="item">
                                <div class="f-label">Default Fee</div>
                                <input type="text" placeholder="$0.00" onBlur={()=>{format_conv_money('default_fee')}} id="default_fee"/>
                            </div>

                            <div class="item">
                                <div class="f-label">Nsf Fee</div>
                                <input type="text" placeholder="$0.00" onBlur={()=>{format_conv_money('nsf_fee')}} id="nsf_fee"/>
                            </div>

                        </div>

                        <div class="field-group-set row-3">

                            <div class="item">
                                <div class="f-label">Month Servicing Fee</div>
                                <input type="text" placeholder="$0.00" onBlur={()=>{format_conv_money('month_servicing_fee')}} id="month_servicing_fee"/>
                            </div>

                            <div class="item">
                                <div class="f-label">Disbursement Fee</div>
                                <input type="text" placeholder="$0.00" onBlur={()=>{format_conv_money('disbursement_fee')}} id="disbursement_fee"/>
                            </div>

                            <div class="item">
                                <div class="f-label">Disbursement Amount</div>
                                <input type="text" placeholder="$0.00" onBlur={()=>{format_conv_money('disbursement_amount')}} id="disbursement_amount"/>
                            </div>

                        </div>
                        <div class="field-group-set row-3">

                            <div class="item">
                                <div class="f-label">Monthly Average Sales</div>
                                <input type="text" placeholder="$0.00" onBlur={()=>{format_conv_money('monthly_average_sales')}} id="monthly_average_sales"/>
                            </div>

                            <div class="item">
                                <div class="f-label">Annual Sales</div>
                                <input type="text" placeholder="$0.00" onBlur={()=>{format_conv_money('annual_sales')}} id="annual_sales" />
                            </div>

                            <div class="item">
                                <div class="f-label">Date Business Started</div>
                                <input type="date" id="date_business_started"/>
                            </div>

                        </div>
                        <div class="field-group-set row-3">

                            <div class="item">
                                <div class="f-label">Funding Fee</div>
                                <input type="text" placeholder="$0.00" onBlur={()=>{format_conv_money('funding_fee')}} id="funding_fee"/>
                            </div>

                            <div class="item">
                                <div class="f-label">Payoff Existing Balance</div>
                                <input type="text" placeholder="$0.00" onBlur={()=>{format_conv_money('payoff_existing_balance')}} id="payoff_existing_balance"/>
                            </div>


                        </div>

                    </div>
                    <div class="prem-send">
                        <button type="button" onClick={saveuserdata}>Next</button>
                    </div>



                </div>
                <Footer />


            </div>
        </div>
    )
}
